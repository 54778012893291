<template>
  <validation-provider
    v-if="!validateDisabled"
    :name="uuid"
    :rules="required ? { is_true: true } : null"
    :customMessages="$store.state.validatorMessages"
    ref="validationProvider"
    v-slot="validationState"
    mode="lazy"
    tag="div"
    :detect-input="false"
    :skip-if-empty="false"
    :disabled="validateDisabled"
  >
    <button
      v-if="button"
      class="btn"
      :class="{
        [`btn-${color}`]: color,
        active: value,
        'text-success':
          (validationState.dirty && validationState.valid) ||
          validState === true,
        'text-danger':
          (validationState.dirty && !validationState.valid) ||
          validState === false
      }"
      @click="$emit('input', !value)"
      :disabled="disabled"
    >
      <slot name="label">{{ label }}</slot>
      <span class="text-danger" v-if="required">*</span>
      <SvgIcon
        class="ms-1"
        color="current"
        v-if="table"
        icon="table"
        height="16"
      ></SvgIcon>
      <SvgIcon
        class="ms-1"
        color="current"
        v-if="eventTypeRequired"
        icon="box-alt"
        height="16"
      ></SvgIcon>
    </button>
    <div
      v-else
      class="form-check"
      :class="{ 'form-check-inline': inline, 'form-switch': switcher }"
    >
      <label
        class="form-check-label"
        :class="{
          'text-success':
            !button &&
            ((validationState.dirty && validationState.valid) ||
              validState === true),
          'text-danger':
            !button &&
            ((validationState.dirty && !validationState.valid) ||
              validState === false)
        }"
      >
        <input
          type="checkbox"
          :class="{
            'btn-check': button,
            'form-check-input': !button,
            'is-valid':
              !button &&
              ((validationState.dirty && validationState.valid) ||
                validState === true),
            'is-invalid':
              !button &&
              ((validationState.dirty && !validationState.valid) ||
                validState === false)
          }"
          :checked="value"
          ref="myInput"
          @input="$emit('input', $event.target.checked)"
          :disabled="disabled"
        />
        <slot name="label">{{ label }}</slot>
        <span class="text-danger" v-if="required">*</span>
        <SvgIcon
          class="ms-1"
          color="current"
          v-if="table"
          icon="table"
          height="16"
        ></SvgIcon>
        <SvgIcon
          class="ms-1"
          color="current"
          v-if="eventTypeRequired"
          icon="box-alt"
          height="16"
        ></SvgIcon>
      </label>
    </div>
    <div v-if="!validateDisabled">
      <div
        v-for="(error, key) in validationState.errors"
        class="invalid-feedback d-block"
        :key="key"
      >
        {{ error }}
      </div>
      <div class="form-text">
        <slot name="help">{{ help }}</slot>
      </div>
    </div>
  </validation-provider>
  <button
    v-else-if="button"
    class="btn"
    :class="{
      [`btn-${color}`]: color,
      active: value
    }"
    @click="$emit('input', !value)"
    :disabled="disabled"
  >
    {{ label }}
    <span class="text-danger" v-if="required">*</span>
    <SvgIcon
      class="ms-1"
      color="current"
      v-if="table"
      icon="table"
      height="16"
    ></SvgIcon>
    <SvgIcon
      class="ms-1"
      color="current"
      v-if="eventTypeRequired"
      icon="box-alt"
      height="16"
    ></SvgIcon>
  </button>
  <div
    v-else
    class="form-check"
    :class="{ 'form-check-inline': inline, 'form-switch': switcher }"
  >
    <label
      class="form-check-label"
      :class="{
        'text-success': !button && validState === true,
        'text-danger': !button && validState === false
      }"
    >
      <input
        type="checkbox"
        :class="{
          'btn-check': button,
          'form-check-input': !button,
          'is-valid': !button && validState === true,
          'is-invalid': !button && validState === false
        }"
        :checked="value"
        ref="myInput"
        @input="$emit('input', $event.target.checked)"
        :disabled="disabled"
      />
      {{ label }}
      <span class="text-danger" v-if="required">*</span>
      <SvgIcon
        class="ms-1"
        color="current"
        v-if="table"
        icon="table"
        height="16"
      ></SvgIcon>
      <SvgIcon
        class="ms-1"
        color="current"
        v-if="eventTypeRequired"
        icon="box-alt"
        height="16"
      ></SvgIcon>
    </label>
  </div>
</template>

<script>
import SvgIcon from "../base/SvgIcon";
import { extend, ValidationProvider } from "vee-validate";
import { v4 as uuidv4 } from "uuid";

extend("is_true", {
  validate: value => {
    return value === true;
  },
  message: "Данное поле должно быть заполнено"
});
export default {
  name: "CheckBoxInput",
  components: { SvgIcon, ValidationProvider },
  props: {
    help: {
      type: String
    },
    required: {
      type: [Boolean, Number],
      default: () => false
    },
    table: {
      type: Boolean,
      default: () => false
    },
    eventTypeRequired: {
      type: Boolean,
      default: () => false
    },
    value: Boolean,
    label: String,
    inline: Boolean,
    switcher: Boolean,
    button: Boolean,
    color: String,
    disabled: Boolean,
    validateDisabled: {
      type: Boolean,
      default: () => true
    },
    validState: {
      type: Boolean,
      default: () => null
    }
  },
  data: () => ({
    uuid: uuidv4()
  }),
  watch: {
    value(val) {
      if (!this.validateDisabled) {
        this.$refs.validationProvider.validate(val);
        this.$refs.validationProvider.setFlags({ dirty: true });
      }
    }
  }
};
</script>
