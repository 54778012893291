var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.validateDisabled)?_c('validation-provider',{ref:"validationProvider",attrs:{"name":_vm.uuid,"rules":_vm.required ? { is_true: true } : null,"customMessages":_vm.$store.state.validatorMessages,"mode":"lazy","tag":"div","detect-input":false,"skip-if-empty":false,"disabled":_vm.validateDisabled},scopedSlots:_vm._u([{key:"default",fn:function(validationState){
var _obj;
return [(_vm.button)?_c('button',{staticClass:"btn",class:( _obj = {}, _obj[("btn-" + _vm.color)] = _vm.color, _obj.active = _vm.value, _obj['text-success'] = 
        (validationState.dirty && validationState.valid) ||
        _vm.validState === true, _obj['text-danger'] = 
        (validationState.dirty && !validationState.valid) ||
        _vm.validState === false, _obj ),attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('input', !_vm.value)}}},[_vm._t("label",[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.table)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"table","height":"16"}}):_vm._e(),(_vm.eventTypeRequired)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"box-alt","height":"16"}}):_vm._e()],2):_c('div',{staticClass:"form-check",class:{ 'form-check-inline': _vm.inline, 'form-switch': _vm.switcher }},[_c('label',{staticClass:"form-check-label",class:{
        'text-success':
          !_vm.button &&
          ((validationState.dirty && validationState.valid) ||
            _vm.validState === true),
        'text-danger':
          !_vm.button &&
          ((validationState.dirty && !validationState.valid) ||
            _vm.validState === false)
      }},[_c('input',{ref:"myInput",class:{
          'btn-check': _vm.button,
          'form-check-input': !_vm.button,
          'is-valid':
            !_vm.button &&
            ((validationState.dirty && validationState.valid) ||
              _vm.validState === true),
          'is-invalid':
            !_vm.button &&
            ((validationState.dirty && !validationState.valid) ||
              _vm.validState === false)
        },attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.checked)}}}),_vm._t("label",[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.table)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"table","height":"16"}}):_vm._e(),(_vm.eventTypeRequired)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"box-alt","height":"16"}}):_vm._e()],2)]),(!_vm.validateDisabled)?_c('div',[_vm._l((validationState.errors),function(error,key){return _c('div',{key:key,staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(error)+" ")])}),_c('div',{staticClass:"form-text"},[_vm._t("help",[_vm._v(_vm._s(_vm.help))])],2)],2):_vm._e()]}}],null,true)}):(_vm.button)?_c('button',{staticClass:"btn",class:( _obj = {}, _obj[("btn-" + _vm.color)] = _vm.color, _obj.active = _vm.value, _obj ),attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('input', !_vm.value)}}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.table)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"table","height":"16"}}):_vm._e(),(_vm.eventTypeRequired)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"box-alt","height":"16"}}):_vm._e()],1):_c('div',{staticClass:"form-check",class:{ 'form-check-inline': _vm.inline, 'form-switch': _vm.switcher }},[_c('label',{staticClass:"form-check-label",class:{
      'text-success': !_vm.button && _vm.validState === true,
      'text-danger': !_vm.button && _vm.validState === false
    }},[_c('input',{ref:"myInput",class:{
        'btn-check': _vm.button,
        'form-check-input': !_vm.button,
        'is-valid': !_vm.button && _vm.validState === true,
        'is-invalid': !_vm.button && _vm.validState === false
      },attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"checked":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.checked)}}}),_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.table)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"table","height":"16"}}):_vm._e(),(_vm.eventTypeRequired)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"box-alt","height":"16"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }