<template>
  <div>
    <RadioButtonsInput
      v-if="['radio_buttons', 'select'].includes(type)"
      :table="edit && table"
      :event-type-required="edit && !!eventTypeRequired"
      :items="items"
      :value="newValue"
      @input="e => $emit('input', e)"
      :inline="inline"
      :inline-label="inlineLabel"
      :label="label"
      :select="select || type === 'select'"
      :button="button"
      :button-group="buttonGroup"
      :color="color"
      :disabled="disabled || edit"
      :help="help"
      :required="required"
      :validate-disabled="validateDisabled"
    >
      <template v-slot:label
        ><slot name="label">{{ label }}</slot></template
      >
      <template v-slot:help
        ><slot name="help">{{ help }}</slot></template
      >
    </RadioButtonsInput>
    <CheckBoxGroupInput
      :table="edit && table"
      :event-type-required="edit && !!eventTypeRequired"
      v-else-if="type === 'checkbox_group'"
      :items="items"
      :value="newValue"
      @input="e => $emit('input', e)"
      :inline="inline"
      :inline-label="inlineLabel"
      :label="label"
      :switcher="switcher"
      :button="button"
      :button-group="buttonGroup"
      :color="color"
      :disabled="disabled || edit"
      :help="help"
      :required="required"
      :validate-disabled="validateDisabled"
    >
      <template v-slot:label
        ><slot name="label">{{ label }}</slot></template
      >
      <template v-slot:help
        ><slot name="help">{{ help }}</slot></template
      >
    </CheckBoxGroupInput>
    <CheckBoxInput
      :table="edit && table"
      :event-type-required="edit && !!eventTypeRequired"
      v-else-if="type === 'checkbox'"
      :value="newValue"
      @input="e => $emit('input', e)"
      :inline="inline"
      :label="label"
      :switcher="switcher"
      :button="button"
      :color="color"
      :disabled="disabled || edit"
      :help="help"
      :required="required"
      :validate-disabled="validateDisabled"
    >
    </CheckBoxInput>
    <BaseInput
      :table="edit && table"
      :event-type-required="edit && !!eventTypeRequired"
      v-else
      :value="newValue"
      @input="e => $emit('input', e)"
      :inline-label="inlineLabel"
      :label="label"
      :placeholder="placeholder"
      :type="type"
      :is-range="isRange"
      :disabled="disabled || edit"
      :help="help"
      :step="step"
      :min="min"
      :max="max"
      :max-length="maxLength"
      :max-size="maxSize"
      :file-types="fileTypes"
      :required="required"
      :validate-disabled="validateDisabled"
    >
      <template v-slot:label
        ><slot name="label">{{ label }}</slot></template
      >
      <template v-slot:help
        ><slot name="help">{{ help }}</slot></template
      >
      <template v-slot:prepend>
        <slot name="prepend"></slot>
      </template>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
    </BaseInput>
  </div>
</template>

<script>
import BaseInput from "./BaseInput";
import CheckBoxInput from "./CheckBoxInput";
import CheckBoxGroupInput from "./CheckBoxGroupInput";
import RadioButtonsInput from "./RadioButtonsInput";
export default {
  name: "RegInput",
  components: {
    RadioButtonsInput,
    CheckBoxGroupInput,
    CheckBoxInput,
    BaseInput
  },
  props: {
    help: {
      type: String
    },
    step: {
      type: Number
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    maxLength: {
      type: Number
    },
    maxSize: {
      type: Number
    },
    fileTypes: {
      type: Array
    },
    required: {
      type: [Boolean, Number],
      default: () => false
    },
    table: {
      type: Boolean,
      default: () => false
    },
    eventTypeRequired: {
      type: [Boolean, Number],
      default: () => false
    },
    value: {
      default: () => null
    },
    items: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: () => "text"
    },
    label: {
      type: String,
      required: false
    },
    color: {
      type: String,
      default: () => "outline-primary brand-style"
    },
    placeholder: {
      type: String,
      required: false
    },
    select: {
      type: Boolean,
      default: () => false
    },
    button: {
      type: Boolean,
      default: () => false
    },
    buttonGroup: {
      type: Boolean,
      default: () => false
    },
    block: {
      type: Boolean,
      default: () => false
    },
    inlineLabel: {
      type: Boolean,
      default: () => false
    },
    inline: {
      type: null,
      default: () => false
    },
    switcher: {
      type: Boolean,
      default: () => false
    },
    isRange: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    edit: {
      type: Boolean,
      default: () => false
    },
    validateDisabled: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    newValue() {
      if (["date"].includes(this.type) && this.value)
        return this.value instanceof Date ? this.value : new Date(this.value);
      return this.value;
    }
  }
};
</script>
