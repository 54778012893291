<template>
  <validation-provider
    :name="uuid"
    :rules="required ? { required_box: requiredNumber } : null"
    :customMessages="$store.state.validatorMessages"
    ref="validationProvider"
    v-slot="validationState"
    mode="lazy"
    tag="div"
    :detect-input="false"
    :skip-if-empty="false"
    :disabled="validateDisabled"
  >
    <div :class="{ 'inline-label': inlineLabel }">
      <label
        :class="{
          'd-block': !inlineLabel,
          'text-success': validationState.dirty && validationState.valid,
          'text-danger': validationState.dirty && !validationState.valid
        }"
      >
        <slot name="label">{{ label }}</slot>
        <span class="text-danger" v-if="required">*</span>
        <SvgIcon
          class="ms-1"
          color="current"
          v-if="table"
          icon="table"
          height="16"
        >
        </SvgIcon>
        <SvgIcon
          class="ms-1"
          color="current"
          v-if="eventTypeRequired"
          icon="box-alt"
          height="16"
        ></SvgIcon>
      </label>
      <div
        :class="{
          'd-flex': inline,
          [`justify-content-${inline}`]: inline && inline !== true,
          [`btn-group${inline ? '' : '-vertical'}`]: buttonGroup,
          'w-100': !inline
        }"
        role="group"
      >
        <CheckBoxInput
          v-for="(item, key) in filteredItems"
          :key="key"
          :label="item.label"
          :value="innerValue.includes(item.value)"
          @input="changeState(item.value, $event)"
          :inline="!!inline"
          :switcher="switcher"
          :button="button"
          :color="color"
          :disabled="disabled"
          :validate-disabled="true"
          ref="input"
          :valid-state="validationState.dirty ? validationState.valid : null"
        ></CheckBoxInput>
      </div>
      <div
        v-for="(error, key) in validationState.dirty
          ? validationState.errors
          : []"
        class="invalid-feedback d-block"
        :key="key"
      >
        {{ error }}
      </div>
      <div v-if="required" class="form-text">
        <span class="text-danger" v-if="required">*</span> Минимальное
        количество выбранных пунктов: {{ requiredNumber }}
      </div>
      <div class="form-text">
        <slot name="help">{{ help }}</slot>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import CheckBoxInput from "./CheckBoxInput";
import SvgIcon from "../base/SvgIcon";
import { extend, ValidationProvider } from "vee-validate";
import { v4 as uuidv4 } from "uuid";

extend("required_box", {
  validate: (value, { amount }) => {
    return value && value.length && value.length >= amount;
  },
  params: ["amount"],
  message: "Вы выбрали меньше полей, чем {amount}"
});

export default {
  name: "CheckBoxGroupInput",
  components: { CheckBoxInput, SvgIcon, ValidationProvider },
  props: {
    help: {
      type: String
    },
    required: {
      type: [Boolean, Number],
      default: () => false
    },
    table: {
      type: Boolean,
      default: () => false
    },
    eventTypeRequired: {
      type: Boolean,
      default: () => false
    },
    items: Array,
    value: Array,
    inline: {
      required: false
    },
    inlineLabel: {
      type: Boolean,
      default: () => false
    },
    label: {
      type: String,
      required: false
    },
    switcher: {
      type: Boolean,
      default: () => false
    },
    button: {
      type: Boolean,
      default: () => false
    },
    buttonGroup: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: () => "outline-primary brand-style"
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    validateDisabled: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({
    uuid: uuidv4()
  }),
  computed: {
    innerValue() {
      return this.value || [];
    },
    requiredNumber() {
      if (this.required) {
        if (this.required === true) {
          return 1;
        }
        return this.required;
      }
      return 0;
    },
    filteredItems() {
      return (this.items || []).filter(val => !val.deleted);
    }
  },
  watch: {
    value(val) {
      if (!this.validateDisabled) {
        this.$refs.validationProvider.validate(val);
        this.$refs.validationProvider.setFlags({ dirty: true });
      }
    }
  },
  mounted() {
    if (!this.validateDisabled) {
      this.$refs.validationProvider.validate(this.value || []);
    }
  },
  methods: {
    changeState(val, state) {
      let hasVal = !!this.innerValue.find(buf => buf === val);
      let newValue = [];
      if (state && !hasVal) {
        newValue = [...this.innerValue, val];
      } else if (!state && hasVal) {
        newValue = this.innerValue.filter(buf => buf !== val);
      } else {
        newValue = [...this.innerValue];
      }
      this.$emit("input", newValue);
    }
  }
};
</script>
