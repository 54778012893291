var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"validationProvider",attrs:{"name":_vm.uuid,"rules":_vm.required ? { required_box: _vm.requiredNumber } : null,"customMessages":_vm.$store.state.validatorMessages,"mode":"lazy","tag":"div","detect-input":false,"skip-if-empty":false,"disabled":_vm.validateDisabled},scopedSlots:_vm._u([{key:"default",fn:function(validationState){
var _obj;
return [_c('div',{class:{ 'inline-label': _vm.inlineLabel }},[_c('label',{class:{
        'd-block': !_vm.inlineLabel,
        'text-success': validationState.dirty && validationState.valid,
        'text-danger': validationState.dirty && !validationState.valid
      }},[_vm._t("label",[_vm._v(_vm._s(_vm.label))]),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.table)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"table","height":"16"}}):_vm._e(),(_vm.eventTypeRequired)?_c('SvgIcon',{staticClass:"ms-1",attrs:{"color":"current","icon":"box-alt","height":"16"}}):_vm._e()],2),_c('div',{class:( _obj = {
        'd-flex': _vm.inline
      }, _obj[("justify-content-" + _vm.inline)] = _vm.inline && _vm.inline !== true, _obj[("btn-group" + (_vm.inline ? '' : '-vertical'))] = _vm.buttonGroup, _obj['w-100'] =  !_vm.inline, _obj ),attrs:{"role":"group"}},_vm._l((_vm.filteredItems),function(item,key){return _c('CheckBoxInput',{key:key,ref:"input",refInFor:true,attrs:{"label":item.label,"value":_vm.innerValue.includes(item.value),"inline":!!_vm.inline,"switcher":_vm.switcher,"button":_vm.button,"color":_vm.color,"disabled":_vm.disabled,"validate-disabled":true,"valid-state":validationState.dirty ? validationState.valid : null},on:{"input":function($event){return _vm.changeState(item.value, $event)}}})}),1),_vm._l((validationState.dirty
        ? validationState.errors
        : []),function(error,key){return _c('div',{key:key,staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(error)+" ")])}),(_vm.required)?_c('div',{staticClass:"form-text"},[(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_vm._v(" Минимальное количество выбранных пунктов: "+_vm._s(_vm.requiredNumber)+" ")]):_vm._e(),_c('div',{staticClass:"form-text"},[_vm._t("help",[_vm._v(_vm._s(_vm.help))])],2)],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }